










import {defineComponent} from '@vue/composition-api';
import StembleCanvas from '@/common/components/StembleCanvas.vue';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';

export default defineComponent({
  name: 'HappyFaceDrawing',
  components: {StembleCanvas},
  mixins: [DynamicQuestionMixin()],
  methods: {
    logImage() {
      console.log(this.attachment);
    },
  },
  data() {
    return {
      attachment: null,
    };
  },
});
